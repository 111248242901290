<template>
  <div class="mb-5">
    <energy-consume-chart
      :firstIdentifier="chartIdentifier(1)"
      :secondIdentifier="chartIdentifier(2)"
      :province="currentProvince"
    >
    </energy-consume-chart>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(3)"
      title="การใช้พลังงานใน สาขาบ้านอยู่อาศัย (ktoe)"
      :fetchPromise="fetchResidentialSector"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(4)"
      title="การใช้พลังงานใน สาขาเกษตรกรรม (ktoe)"
      :fetchPromise="fetchAgriculturalSector"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(5)"
      title="การใช้พลังงานใน สาขาอุตสาหกรรม (ktoe)"
      :fetchPromise="fetchIndustrialSector"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(6)"
      title="การใช้พลังงานใน สาขาธุรกิจการค้า (ktoe)"
      :fetchPromise="fetchCommercialSector"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(7)"
      title="การใช้พลังงานใน สาขาการขนส่ง (ktoe)"
      :fetchPromise="fetchTransportationSector"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(8)"
      title="การใช้พลังงานใน สาขาอื่นๆ (เช่น เหมืองแร่และการก่อสร้าง) (ktoe)"
      :fetchPromise="fetchOtherSector"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>

    <chart-box
      chartType="line"
      :identifier="chartIdentifier(9)"
      title="การใช้ แอลพีจี (ktoe)"
      :fetchPromise="fetchLPG"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(10)"
      title="การใช้ ดีเซลหมุนช้า (ktoe)"
      :fetchPromise="fetchLSD"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(11)"
      title="การใช้ ดีเซลหมุนเร็ว/ไบโอดีเซล (ktoe)"
      :fetchPromise="fetchHSD"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(12)"
      title="การใช้ น้ำมันเตา (ktoe)"
      :fetchPromise="fetchFuelOil"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(13)"
      title="การใช้ แก๊สโซลีน  91 (ktoe)"
      :fetchPromise="fetchGasoline91"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(14)"
      title="การใช้ แก๊สโซลีน  95 (ktoe)"
      :fetchPromise="fetchGasoline95"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(15)"
      title="การใช้ แก๊สโซฮอล์ 91 (ktoe)"
      :fetchPromise="fetchGasohol91"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(16)"
      title="การใช้ แก๊สโซฮอล์ 95 (ktoe)"
      :fetchPromise="fetchGasohol95"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(17)"
      title="การใช้ ก๊าซธรรมชาติ (ktoe)"
      :fetchPromise="fetchNaturalGas"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
    <chart-box
      chartType="line"
      :identifier="chartIdentifier(18)"
      title="การใช้พลังงานไฟฟ้า (ktoe)"
      :fetchPromise="fetchElectricity"
      :tableFirstColumnNoFilter="true"
    >
    </chart-box>
  </div>
</template>

<script>
import Vue from 'vue';
import ChartBox from '@/components/ChartBox.vue';
import EnergyConsumeChart from '@/components/EnergyConsumeChart.vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import {
  get,
  head,
  isEmpty,
  values,
  forIn,
  range,
  keys,
  sum,
  reduce,
  add,
  uniq,
  map,
  flatMap,
  compact,
} from 'lodash';

export default {
  name: 'energy-usage',

  components: {
    ChartBox,
    EnergyConsumeChart,
  },

  data() {
    return {
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      topicNumber: '2.2',
      energyByTypeData: {
        show: false,
        title: 'การใช้พลังงานในปีล่าสุด ตามประเภทพลังงาน',
        unit: ' ktoe',
        data: [
          ['Renewables', 'ktoe'],
          ['Biomass heat', 1203],
          ['Solar hotwater', 17],
          ['Geothermal heat', 12],
          ['Hydropower', 341],
          ['Ethanol', 50],
          ['Biodiesel', 17],
          ['Biomass electricity', 28],
          ['Wind power', 51],
          ['Geothermal electricity', 7],
          ['Solar PV power', 6],
          ['Solar CSP', 0.2],
          ['Ocean power', 0.1],
        ],
      },
      energyBySectorData: {
        show: false,
        title: 'การใช้พลังงานในปีล่าสุด ตามสาขา',
        unit: ' ktoe',
        data: [
          ['สาขา', 'ktoe'],
          ['ครัวเรือน', 1520],
          ['เกษตรกรรม', 894],
          ['อุตสาหกรรม', 500],
          ['ธุรกิจการค้า', 1052],
          ['ขนส่ง', 480],
          ['อื่นๆ', 300],
        ],
      },
    };
  },

  created() {},

  computed: {
    ...mapGetters('peno', ['currentProvince']),
    ...mapGetters(['latestNationalPlan']),
    currentYear() {
      return this.$options.filters.thai_year(this.latestNationalPlan.year);
    },
  },

  methods: {
    ...mapActions('mondas', [
      'fetchPreEnergyConsume',
      'fetchEnergyConsumeLastMutiYear',
    ]),
    chartIdentifier(number) {
      return this.topicNumber + '.' + number;
    },
    fetchResidentialSector() {
      const energyFields = {
        RDS_LPGConsRes: 'แอลพีจี',
        RDS_LSDConsRes: 'ดีเซลหมุนช้า',
        RDS_HSDConsRes: 'ดีเซลหมุนเร็ว/ไบโอดีเซล',
        RDS_Gasl91ConsRes: 'แก๊สโซลีน 91',
        RDS_Gasl95ConsRes: 'แก๊สโซลีน 95',
        RDS_Gash91ConsRes: 'แก๊สโซฮอล์ 91',
        RDS_Gash95ConsRes: 'แก๊สโซฮอล์ 95',
        RDS_ElConsRes: 'ไฟฟ้า',
      };
      return this.fetchEnergyLastMutiYear(
        'การใช้พลังงานใน สาขาบ้านอยู่อาศัย (ktoe)',
        energyFields
      );
    },
    fetchAgriculturalSector() {
      const energyFields = {
        RDS_LSDConsAgr: 'ดีเซลหมุนช้า',
        RDS_HSDConsAgr: 'ดีเซลหมุนเร็ว/ไบโอดีเซล',
        RDS_OilConsAgr: 'น้ำมันเตา',
        RDS_Gasl91ConsAgr: 'แก๊สโซลีน 91',
        RDS_Gasl95ConsAgr: 'แก๊สโซลีน 95',
        RDS_Gash91ConsAgr: 'แก๊สโซฮอล์ 91',
        RDS_Gash95ConsAgr: 'แก๊สโซฮอล์ 95',
        RDS_ElConsRes: 'ไฟฟ้า',
      };
      return this.fetchEnergyLastMutiYear(
        'การใช้พลังงานใน สาขาเกษตรกรรม (ktoe)',
        energyFields
      );
    },
    fetchIndustrialSector() {
      const energyFields = {
        RDS_LPGConsInd: 'แอลพีจี',
        RDS_LSDConsInd: 'ดีเซลหมุนช้า',
        RDS_HSDConsInd: 'ดีเซลหมุนเร็ว/ไบโอดีเซล',
        RDS_OilConsInd: 'น้ำมันเตา',
        RDS_Gasl91ConsInd: 'แก๊สโซลีน 91',
        RDS_Gasl95ConsInd: 'แก๊สโซลีน 95',
        RDS_Gash91ConsInd: 'แก๊สโซฮอล์ 91',
        RDS_Gash95ConsInd: 'แก๊สโซฮอล์ 95',
        RDS_NGasConsInd: 'ก๊าซธรรมชาติ',
        RDS_ElConsInd: 'ไฟฟ้า',
      };
      return this.fetchEnergyLastMutiYear(
        'การใช้พลังงานใน สาขาอุตสาหกรรม (ktoe)',
        energyFields
      );
    },
    fetchCommercialSector() {
      const energyFields = {
        RDS_LPGConsCom: 'แอลพีจี',
        RDS_LSDConsCom: 'ดีเซลหมุนช้า',
        RDS_HSDConsCom: 'ดีเซลหมุนเร็ว/ไบโอดีเซล',
        RDS_OilConsCom: 'น้ำมันเตา',
        RDS_Gasl91ConsCom: 'แก๊สโซลีน 91',
        RDS_Gasl95ConsCom: 'แก๊สโซลีน 95',
        RDS_Gash91ConsCom: 'แก๊สโซฮอล์ 91',
        RDS_Gash95ConsCom: 'แก๊สโซฮอล์ 95',
        RDS_NGasConsCom: 'ก๊าซธรรมชาติ',
        RDS_ElConsCom: 'ไฟฟ้า',
      };
      return this.fetchEnergyLastMutiYear(
        'การใช้พลังงานใน สาขาธุรกิจการค้า (ktoe)',
        energyFields
      );
    },
    fetchTransportationSector() {
      const energyFields = {
        RDS_LPGConsTrans: 'แอลพีจี',
        RDS_LSDConsTrans: 'ดีเซลหมุนช้า',
        RDS_HSDConsTrans: 'ดีเซลหมุนเร็ว/ไบโอดีเซล',
        RDS_OilConsTrans: 'น้ำมันเตา',
        RDS_Gasl91ConsTrans: 'แก๊สโซลีน 91',
        RDS_Gasl95ConsTrans: 'แก๊สโซลีน 95',
        RDS_Gash91ConsTrans: 'แก๊สโซฮอล์ 91',
        RDS_Gash95ConsTrans: 'แก๊สโซฮอล์ 95',
        RDS_NGasConsTrans: 'ก๊าซธรรมชาติ',
        RDS_ElConsTrans: 'ไฟฟ้า',
      };
      return this.fetchEnergyLastMutiYear(
        'การใช้พลังงานใน สาขาการขนส่ง (ktoe)',
        energyFields
      );
    },
    fetchOtherSector() {
      const energyFields = {
        RDS_LPGConsOther: 'แอลพีจี',
        RDS_LSDConsOther: 'ดีเซลหมุนช้า',
        RDS_HSDConsOther: 'ดีเซลหมุนเร็ว/ไบโอดีเซล',
        RDS_OilConsOther: 'น้ำมันเตา',
        RDS_Gasl91ConsOther: 'แก๊สโซลีน 91',
        RDS_Gasl95ConsOther: 'แก๊สโซลีน 95',
        RDS_Gash91ConsOther: 'แก๊สโซฮอล์ 91',
        RDS_Gash95ConsOther: 'แก๊สโซฮอล์ 95',
        RDS_NGasConsOther: 'ก๊าซธรรมชาติ',
        RDS_ElConsOther: 'ไฟฟ้า',
      };
      return this.fetchEnergyLastMutiYear(
        'การใช้พลังงานใน สาขาอื่นๆ (เช่น เหมืองแร่และการก่อสร้าง) (ktoe)',
        energyFields
      );
    },
    fetchLPG() {
      const energyFields = {
        RDS_LPGConsRes: 'สาขาบ้านอยู่อาศัย',
        RDS_LPGConsInd: 'สาขาอุตสาหกรรม',
        RDS_LPGConsCom: 'สาขาธุรกิจการค้า',
        RDS_LPGConsTrans: 'สาขาการขนส่ง',
        RDS_LPGConsOther: 'สาขาอื่นๆ',
      };
      return this.fetchEnergyLastMutiYear(
        'การใช้พลังงาน แอลพีจี (ktoe)',
        energyFields
      );
    },
    fetchLSD() {
      const energyFields = {
        RDS_LSDConsRes: 'สาขาบ้านอยู่อาศัย',
        RDS_LSDConsAgr: 'สาขาเกษตรกรรม',
        RDS_LSDConsInd: 'สาขาอุตสาหกรรม',
        RDS_LSDConsCom: 'สาขาธุรกิจการค้า',
        RDS_LSDConsTrans: 'สาขาการขนส่ง',
        RDS_LSDConsOther: 'สาขาอื่นๆ',
      };
      return this.fetchEnergyLastMutiYear(
        'การใช้พลังงาน ดีเซลหมุนช้า (ktoe)',
        energyFields
      );
    },
    fetchHSD() {
      const energyFields = {
        RDS_HSDConsRes: 'สาขาบ้านอยู่อาศัย',
        RDS_HSDConsAgr: 'สาขาเกษตรกรรม',
        RDS_HSDConsInd: 'สาขาอุตสาหกรรม',
        RDS_HSDConsCom: 'สาขาธุรกิจการค้า',
        RDS_HSDConsTrans: 'สาขาการขนส่ง',
        RDS_HSDConsOther: 'สาขาอื่นๆ',
      };
      return this.fetchEnergyLastMutiYear(
        'การใช้พลังงาน ดีเซลหมุนเร็ว/ไบโอดีเซล (ktoe)',
        energyFields
      );
    },
    fetchFuelOil() {
      const energyFields = {
        RDS_OilConsAgr: 'สาขาเกษตรกรรม',
        RDS_OilConsInd: 'สาขาอุตสาหกรรม',
        RDS_OilConsCom: 'สาขาธุรกิจการค้า',
        RDS_OilConsTrans: 'สาขาการขนส่ง',
        RDS_OilConsOther: 'สาขาอื่นๆ',
      };
      return this.fetchEnergyLastMutiYear(
        'การใช้พลังงาน น้ำมันเตา (ktoe)',
        energyFields
      );
    },
    fetchGasoline91() {
      const energyFields = {
        RDS_Gasl91ConsRes: 'สาขาบ้านอยู่อาศัย',
        RDS_Gasl91ConsAgr: 'สาขาเกาตรกรรม',
        RDS_Gasl91ConsInd: 'สาขาอุตสาหกรรม',
        RDS_Gasl91ConsCom: 'สาขาธุรกิจการค้า',
        RDS_Gasl91ConsTrans: 'สาขาการขนส่ง',
        RDS_Gasl91ConsOther: 'สาขาอื่นๆ',
      };
      return this.fetchEnergyLastMutiYear(
        'การใช้พลังงาน แก๊สโซลีน  91 (ktoe)',
        energyFields
      );
    },
    fetchGasoline95() {
      const energyFields = {
        RDS_Gasl95ConsRes: 'สาขาบ้านอยู่อาศัย',
        RDS_Gasl95ConsAgr: 'สาขาเกษตรกรรม',
        RDS_Gasl95ConsInd: 'สาขาอุตสาหกรรม',
        RDS_Gasl95ConsCom: 'สาขาธุรกิจการค้า',
        RDS_Gasl95ConsTrans: 'สาขาการขนส่ง',
        RDS_Gasl95ConsOther: 'สาขาอื่นๆ',
      };
      return this.fetchEnergyLastMutiYear(
        'การใช้พลังงาน แก๊สโซลีน  95 (ktoe)',
        energyFields
      );
    },
    fetchGasohol91() {
      const energyFields = {
        RDS_Gash91ConsRes: 'สาขาบ้านอยู่อาศัย',
        RDS_Gash91ConsAgr: 'สาขาเกษตรกรรม',
        RDS_Gash91ConsInd: 'สาขาอุตสาหกรรม',
        RDS_Gash91ConsCom: 'สาขาธุรกิจการค้า',
        RDS_Gash91ConsTrans: 'สาขาการขนส่ง',
        RDS_Gash91ConsOther: 'สาขาอื่นๆ',
      };
      return this.fetchEnergyLastMutiYear(
        'การใช้พลังงาน แก๊สโซฮอล์ 91 (ktoe)',
        energyFields
      );
    },
    fetchGasohol95() {
      const energyFields = {
        RDS_Gash95ConsRes: 'สาขาบ้านอยู่อาศัย',
        RDS_Gash95ConsAgr: 'สาขาเกษตรกรรม',
        RDS_Gash95ConsInd: 'สาขาอุตสาหกรรม',
        RDS_Gash95ConsCom: 'สาขาธุรกิจการค้า',
        RDS_Gash95ConsTrans: 'สาขาการขนส่ง',
        RDS_Gash95ConsOther: 'สาขาอื่นๆ',
      };
      return this.fetchEnergyLastMutiYear(
        'การใช้พลังงาน แก๊สโซฮอล์ 95 (ktoe)',
        energyFields
      );
    },
    fetchNaturalGas() {
      const energyFields = {
        RDS_NGasConsOther: 'สาขาอื่นๆ',
        RDS_NGasConsRes: 'สาขาบ้านอยู่อาศัย',
        RDS_NGasConsAgr: 'สาขาเกษตรกรรม',
        RDS_NGasConsInd: 'สาขาอุตสาหกรรม',
        RDS_NGasConsCom: 'สาขาธุรกิจการค้า',
        RDS_NGasConsTrans: 'สาขาการขนส่ง',
      };
      return this.fetchEnergyLastMutiYear(
        'การใช้พลังงาน ก๊าซธรรมชาติ (ktoe)',
        energyFields
      );
    },
    fetchElectricity() {
      const energyFields = {
        RDS_ElConsRes: 'สาขาบ้านอยู่อาศัย',
        RDS_ElConsAgr: 'สาขาเกษตรกรรม',
        RDS_ElConsInd: 'สาขาอุตสาหกรรม',
        RDS_ElConsCom: 'สาขาธุรกิจการค้า',
        RDS_ElConsTrans: 'สาขาการขนส่ง',
        RDS_ElConsOther: 'สาขาอื่นๆ',
      };
      return this.fetchEnergyLastMutiYear(
        'การใช้พลังงาน ไฟฟ้า (ktoe)',
        energyFields
      );
    },
    fetchEnergyLastMutiYear(
      title,
      energyFields,
      sumType = null,
      sumKeye = null,
      sumTitle = ''
    ) {
      return this.fetchPreEnergyConsume({
        province: this.currentProvince,
        key: head(keys(energyFields)),
      }).then((data) => {
        if (data) {
          const generalData = data instanceof Array ? head(data) : data;
          const yearStringQuery = this.getYearQueryString(
            this.currentYear,
            generalData
          );
          const latestYear = this.getTargetYear(generalData);
          return this.fetchEnergyConsumeLastMutiYear({
            yearStringQuery: yearStringQuery,
            province: this.currentProvince,
            keys: keys(energyFields),
          }).then((data) => {
            const result = this.buildEnergyLastMutiYearTableData(
              title,
              energyFields,
              data,
              generalData
            );
            return result;
          });
        }
      });
    },
    buildEnergyLastMutiYearTableData(title, energyFields, data, generalData) {
      let sources = [];
      const plotdata = [['ปี', ...values(energyFields)]];
      if (!isEmpty(data)) {
        const fieldKeys = keys(energyFields);
        forIn(data, (item, year) => {
          let energies = [year];
          forIn(fieldKeys, (key) => {
            energies.push(get(item, key + '.value', 0));
            sources.push(get(item, key + '.source', null));
          });
          plotdata.push(energies);
        });
      } else {
        return null;
      }
      const result = {
        title: title,
        unit: '(ktoe)',
        data: plotdata,
        sources: compact(uniq(sources)),
      };
      return result;
    },
    getYearQueryString(year, generalData) {
      const yearBackRangeIndex = 4;
      let minYear = generalData.min_year;
      let maxYear = generalData.max_year;
      if (minYear && maxYear) {
        let yearInt = parseInt(year);
        if (yearInt < minYear) {
          return null;
        }
        if (yearInt < maxYear) {
          maxYear = yearInt;
        } else {
          yearInt = maxYear;
        }
        if (yearInt - yearBackRangeIndex > minYear) {
          minYear = yearInt - yearBackRangeIndex;
        }
        const yearArray = range(minYear, maxYear + 1);
        const yearStringQuery = yearArray.join(',');
        return yearStringQuery;
      }
    },
    getTargetYear(generalData) {
      let currentYearInt = parseInt(this.currentYear);
      let targetYear = currentYearInt;
      if (generalData) {
        let minYear = generalData.min_year;
        let maxYear = generalData.max_year;
        if (currentYearInt < minYear) {
          targetYear = minYear;
        } else if (currentYearInt > maxYear) {
          targetYear = maxYear;
        }
      }
      return targetYear;
    },
    json(params) {
      return 'data=' + encodeURIComponent(JSON.stringify(params));
    },
  },
};
</script>

<style scoped></style>
